import {
  Business,
  Email,
  Phone,
  Place,
  ContactPage,
} from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";

function Contacts() {
  return (
    <Grid container spacing={1} alignItems="stretch">
      <Grid item xs={12}>
        <Typography
          variant="body1"
          style={{ paddingTop: 0, fontWeight: "bold" }}
        >
          Contacts
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} style={{ display: "flex" }}>
        <Card
          style={{
            width: "100%",
          }}
        >
          <CardHeader
            title="Administration & Facturation"
            titleTypographyProps={{ align: "center" }}
          />
          <CardContent>
            <List>
              <ListItem>
                <ListItemIcon>
                  <Business />
                </ListItemIcon>
                <ListItemText
                  primary="Sudcal S.A."
                  secondary="c/o Mark Lauwers"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Place />
                </ListItemIcon>
                <ListItemText
                  primary="44, Avenue de la Gare"
                  secondary="L-4130 Esch-sur-Alzette"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ContactPage />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="body1" display="block">
                      info@sudcal.lu
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography variant="body1" display="block">
                        Nous vous informons que notre service client n'est actuellement pas accessible par téléphone. Veuillez nous excuser pour ce désagrément.
                        Vous pouvez nous contacter par email pour toute question ou pour demander un rendez-vous. Nous faisons de notre mieux pour répondre à vos questions concernant vos contrats et vos factures dans les plus brefs délais.
                        {/* +352 691395248 */}
                      </Typography>
                      {/* <Typography variant="body1" display="block">
                        Lundi et Mercredi 14h-16h.
                      </Typography> */}
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
          </CardContent>
          <CardActions>
            <Button fullWidth href="mailto:info@sudcal.lu">
              <Email color="primary" />
            </Button>
            {/* <Button fullWidth href="tel:+352691395248">
              <Phone color="primary" />
            </Button> */}
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} style={{ display: "flex" }}>
        <Card
          style={{
            width: "100%",
          }}
        >
          <CardHeader
            title="Exploitation"
            titleTypographyProps={{ align: "center" }}
          />
          <CardContent>
            <List>
              <ListItem>
                <ListItemIcon>
                  <Business />
                </ListItemIcon>
                <ListItemText primary="All In One Technologies S.A." />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Place />
                </ListItemIcon>
                <ListItemText
                  primary="35, Rue Gabriel Lippmann"
                  secondary="Parc d'Activité Syrdall 3, L-5365 Munsbach"
                />
              </ListItem>
              {/* Add a list item containing the mail adress and the phone number with a book as itemicon */}
              <ListItem>
                <ListItemIcon>
                  <ContactPage />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="body1" display="block">
                      service.sudcal@aio.lu
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body1" display="block">
                      +352 27845447
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </CardContent>
          <CardActions>
            <Button fullWidth href="mailto:service@sudcal.aio.lu">
              <Email color="primary" />
            </Button>
            <Button fullWidth href="tel:+35227845447">
              <Phone color="primary" />
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Contacts;
